import Vue from 'vue';
import App from './App.vue';
import router from './router';
import axios from 'axios';
import '../node_modules/bootstrap/scss/bootstrap.scss';
import VueMq from 'vue-mq';
import vueHeadful from 'vue-simple-headful';
import '../node_modules/video.js/dist/video-js.min.css';

// import will initiate datadog
import '@/logger';

import smoothscroll from 'smoothscroll-polyfill';

import i18n, { configureAdminLocale, configureLocale } from './i18n';

import VeeValidate from 'vee-validate';

Vue.use(VeeValidate);

// kick off the polyfill!
smoothscroll.polyfill();

Vue.config.productionTip = false;
axios.defaults.baseURL = process.env.VUE_APP_API;

axios.interceptors.request.use((config) => {
  // We have three situations:
  // - A patient logs in directly and has a token
  // - An admin is logged in with an adminToken
  // - An admin is logged in with an adminToken and then logged in a patient, which now has a regular token
  // In any case, if there is a patient token, it takes preference in the request.
  const token =
    sessionStorage.getItem('token') || sessionStorage.getItem('adminToken');

  if (token) {
    config.headers.setAuthorization(`Bearer ${token}`);
  }
  return config;
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const { status } = error.response;
    if (status === 401) {
      sessionStorage.removeItem('token');
      // router.push('/login');
    }
    return Promise.reject(error);
  }
);

Vue.use(VueMq, {
  breakpoints: {
    small: 576,
    medium: 1200,
    large: Infinity,
  },
});

Vue.use(vueHeadful);

router.beforeEach((to, from, next) => {
  // Check for query URL language updates on each route change. We'll generally
  // only receive query languages on the login pages.
  configureLocale(to.query['language']);
  configureAdminLocale(to.query['admin_language']);
  next();
});

new Vue({
  router: router,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
