<template>
  <div class="videocontainer">
    <video ref="videoPlayer" class="video-js" :poster="videoPoster">
      <source :src="videoUrl" type="video/mp4" />
    </video>
  </div>
</template>

<script setup>
import { onBeforeUnmount, onMounted, ref, computed } from 'vue';
import videojs from 'video.js';
import i18n from '@/i18n';
import { getCurrentUser } from '@/lib/functions';

const player = ref(null);
const props = defineProps({
  video: String,
});

const storageUrl = process.env.VUE_APP_NOVACAIR_STORAGE;
const options = {
  autoplay: false,
  controls: true,
  responsive: true,
  fluid: true,
  techOrder: ['html5'],
};

const videoPlayer = ref(null);

function initPlayer() {
  player.value = videojs(videoPlayer.value, options);
}

function destroyPlayer() {
  player.value?.dispose();
}

const versionPath = computed(() => {
  const patient = getCurrentUser();

  // If the tenant specifies a video handle, we
  // serve the videos from its subpath.
  return patient.video_handle ? `${patient.video_handle}/` : '';
});

// Narcose is an alias for 'full' (apparently, source lost)
const videoName = computed(() =>
  props.video === 'narcose' ? 'full' : props.video
);

const nlOnly = [
  'staaroperatie',
];

const urlBase = computed(() => {
  const name = videoName.value;
  return nlOnly.includes(videoName.value)
    ? `${storageUrl}/videos/nl/${name}`
    : `${storageUrl}/videos/${versionPath.value}${i18n.locale}/${name}`;
});

const videoPoster = computed(() => urlBase.value + '.png');
const videoUrl = computed(() => urlBase.value + '.mp4');

onMounted(() => initPlayer());
onBeforeUnmount(() => destroyPlayer());
</script>

<style scoped lang="scss">
.videocontainer {
  min-width: 100%;
}
</style>
