<template>
  <div v-if="applicableTechniques && anesthesiaVideos" class="wrapper">
    <div
      v-for="video in videos"
      class="videowrapper"
      :class="$mq"
      :key="video.name"
    >
      <div class="videolabel">
        {{ translate(video, 'title') }}
      </div>
      <div class="videodescription">
        {{ translate(video, 'description') }}
      </div>

      <div class="video">
        <video-player :video="video.name" :key="$i18n.locale" />
      </div>
    </div>
  </div>
</template>

<script>
// This component name is a preexisting condition, disable inspection.
/* eslint vue/multi-word-component-names: 0 */
import { uniq, flattenDeep } from 'lodash';
import videoPlayer from '@/components/VideoPlayer.vue';
import languages from '@/languages';

export default {
  components: { videoPlayer },
  props: [
    'screening',
    'anesthesiaVideos',
    'applicableTechniques',
    'procedure',
    'chosenAnesthesiaOnly',
  ],
  async created() {},
  computed: {
    videos() {
      if (this.chosenAnesthesiaOnly && this.screening.chosen_anesthesia) {
        return this.getChosenVideosOnly();
      }

      if (
        this.procedure &&
        (this.screening.mdl_procedure || this.screening.operation_category)
      ) {
        return this.getProcedureVideos();
      }

      return this.getAllApplicableVideos();
    },
  },
  methods: {
    translate(item, key) {
      return languages.translate(this.$i18n.locale, item, key);
    },
    getChosenVideosOnly() {
      const videoNamesToShow = this.applicableTechniques.find(
        (t) => this.screening.chosen_anesthesia === t.value
      ).videos;

      return videoNamesToShow.map((name) => {
        return this.anesthesiaVideos[name];
      });
    },
    getAllApplicableVideos() {
      const videoNamesToShow = uniq(
        flattenDeep(this.applicableTechniques.map((option) => option.videos))
      );

      return videoNamesToShow.map((name) => {
        return this.anesthesiaVideos[name];
      });
    },
    getProcedureVideos() {
      let result = [];

      if (this.screening.mdl_procedure === 'coloscopy') {
        result.push(this.anesthesiaVideos.coloscopy);
      }
      if (this.screening.mdl_procedure === 'gastroscopy') {
        result.push(this.anesthesiaVideos.gastroscopy);
      }
      if (this.screening.mdl_procedure === 'ercp') {
        result.push(this.anesthesiaVideos.ercp);
      }
      if (this.screening.mdl_procedure === 'sigmoidoscopy') {
        result.push(this.anesthesiaVideos.sigmoidoscopy);
      }
      if (this.screening.mdl_procedure === 'cologastro') {
        result.push(this.anesthesiaVideos.coloscopy);
        result.push(this.anesthesiaVideos.gastroscopy);
      }
      if (this.screening.operation_category === 'biopsy') {
        result.push(this.anesthesiaVideos.biopsy);
      }
      if (this.screening.operation_category === 'vasectomy') {
        result.push(this.anesthesiaVideos.vasectomy);
      }

      return result;
    },
  },
};
</script>

<style scoped lang="scss">
.wrapper {
  padding-bottom: 2rem;
  width: 100%;

  hr {
    width: 100%;
  }

  display: flex;
  flex-direction: column;

  .videowrapper {
    display: flex;
    flex-direction: column;
    width: 97%;
    padding-bottom: 2rem;
    padding-top: 2rem;

    &.small {
      padding-top: 0;
    }

    .videolabel {
      font-size: 16px;
      font-weight: 700;
      display: flex;
      flex-direction: row;
      text-align: start;
    }

    .videodescription {
      display: flex;
      flex-direction: row;
      text-align: start;
      padding-bottom: 1rem;
    }

    .video {
      margin: auto;
      width: 100%;
      max-width: 700px;
    }
  }
}
</style>
