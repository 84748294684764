<template>
  <div class="question" :class="(enabled ? '' : 'future ') + $mq">
    <template v-if="question.type === 'info'">
      <info-question-type :questionText="questionText"></info-question-type>
    </template>
    <template v-if="question.type === 'score'">
      <score-question-type
        :questionText="questionText"
        @setAnswer="setAnswer"
        :question="question"
        :answer="answer"
        :enabled="enabled"
        @onClick="onClick"
      />
    </template>
    <template v-if="question.special_type === 'personal-info-check'">
      <personal-question-type :screening="screening"></personal-question-type>
    </template>
    <template v-if="question.special_type === 'animations'">
      <videos
        :anesthesia-videos="anesthesiaVideos"
        :applicable-techniques="applicableTechniques"
        :screening="screening"
      />
    </template>
    <template v-if="question.special_type === 'animations-chosen-only'">
      <videos
        :anesthesia-videos="anesthesiaVideos"
        :applicable-techniques="applicableTechniques"
        :chosenAnesthesiaOnly="true"
        :screening="screening"
      />
    </template>
    <template
      v-if="question.special_type === 'animations-and-download-questionnaire'"
    >
      <download-questionnaire-question-type
        :isSmall="isSmall"
        :screening="screening"
      />
      <videos
        :anesthesia-videos="anesthesiaVideos"
        :applicable-techniques="applicableTechniques"
        :screening="screening"
      />
    </template>
    <template
      v-if="isLocal && question.special_type === 'on-location-finish-button'"
    >
      <div class="flex justify-center">
        <button
          type="button"
          class="btn btn-novacair-selected nextbutton"
          @click="closeScreening"
        >
          Terug naar toegangscode scherm.
        </button>
      </div>
    </template>
    <template v-if="question.special_type === 'procedure-animations'">
      <videos
        :anesthesia-videos="anesthesiaVideos"
        :applicable-techniques="applicableTechniques"
        :screening="screening"
        :procedure="true"
      />
    </template>
    <template v-if="question.special_type === 'download-questionnaire'">
      <download-questionnaire-question-type
        :isSmall="isSmall"
        :screening="screening"
      ></download-questionnaire-question-type>
    </template>
    <template v-if="question.special_type === 'language-choice'">
      <language-choice-question-type
        :questionText="questionText"
        :anesthesia-videos="anesthesiaVideos"
        :applicable-techniques="applicableTechniques"
        :screening="screening"
        :answer="answer"
        :isSmall="isSmall"
        :languages="languages"
        :isLanguageRtl="isLanguageRtl"
        :enabled="enabled"
        @onClick="onClick"
      />
    </template>
    <template v-if="question.special_type === 'anesthesia-description'">
      <anesthesia-description-question-type :screening="screening" />
    </template>

    <template v-if="question.special_type === 'anesthesia-choice'">
      <anesthesia-choice-question-type
        :questionText="questionText"
        :enabled="enabled"
        :answer="answer"
        :screening="screening"
        :questionExplanation="questionExplanation"
        :preferred-technique="preferredTechnique"
        :applicable-techniques="applicableTechniques"
        @onClick="onClick"
        @toggleModal="toggleModal"
      />
    </template>

    <div
      v-if="
        question.type !== 'info' &&
        question.type !== 'score' &&
        question.special_type !== 'anesthesia-choice' &&
        question.special_type !== 'language-choice'
      "
    >
      <div class="question-row">
        <div class="questioncol">
          <label class="question-label">
            <span class="question-text" v-html="questionText"></span>
            <span
              v-if="question.type === 'multiselect'"
              class="multipleanswers"
            >
              {{ $t('multiple-answers') }}</span
            >
          </label>
          <span class="infocol">
            <i
              v-if="questionExplanation"
              class="fal fa-info-circle fa-lg"
              @click="toggleModal(question)"
            />
          </span>
        </div>
        <div
          class="answercol"
          :class="isYesNo(question) ? 'horizontal' : 'vertical'"
          v-if="!['medication'].includes(question.special_type)"
        >
          <template v-if="question.type === 'date'">
            <input
              class="form-control answer"
              v-model="answer"
              v-on:change="debouncedAnswer"
              v-bind:name="question.id"
              @click.stop="click"
              type="date"
            />
          </template>
          <template v-if="question.type === 'text'">
            <textarea
              class="form-control answer"
              v-model="answer"
              rows="4"
              @input="debouncedAnswer"
              @click.stop="click"
              :name="question.id"
            >
            </textarea>
          </template>
          <template v-if="question.type === 'number'">
            <input
              v-validate="numberValidation(question)"
              data-vv-delay="1000"
              @input.prevent="setNumberAnswer"
              @click.stop="click"
              :value="answer"
              class="form-control answer"
              v-bind:name="question.id"
              type="number"
            />
            <span class="validation-message">{{
              errors.first('' + question.id) ? $t('invalid_value') : ''
            }}</span>
          </template>
          <template v-if="question.type === 'select' && isYesNo(question)">
            <div
              class="optionpointer horizontal"
              v-for="option in question.options"
              :key="option.id"
            >
              <button
                class="btn answer"
                @click="onClick(option.id)"
                :class="
                  answer == option.id
                    ? 'btn-novacair-selected'
                    : 'btn-novacair-light'
                "
                :disabled="!enabled"
              >
                {{ optionText(option) }}
              </button>
            </div>
          </template>
          <template
            v-if="
              (question.type === 'select' && !isYesNo(question)) ||
              question.type === 'score'
            "
          >
            <div
              class="optionpointer vertical"
              v-for="option in question.options"
              :key="option.id"
            >
              <button
                class="btn answer"
                @click="onClick(option.id)"
                :class="
                  answer == option.id
                    ? 'btn-novacair-selected'
                    : 'btn-novacair-light'
                "
                :disabled="!enabled"
              >
                {{
                  question.type === 'score' && optionText(option).length > 2
                    ? optionText(option).substr(0, 1) +
                      ' (' +
                      optionText(option).substr(2) +
                      ')'
                    : optionText(option)
                }}
              </button>
            </div>
          </template>
          <template v-if="question.type === 'multiselect'">
            <div
              class="optionpointer vertical"
              v-for="option in question.options"
              :key="option.id"
            >
              <button
                class="btn answer"
                @click="onClickMulti(option.id)"
                :class="
                  selected(option.id)
                    ? 'btn-novacair-selected'
                    : 'btn-novacair-light'
                "
                :disabled="!enabled"
              >
                {{ optionText(option) }}
              </button>
            </div>
          </template>
        </div>
      </div>
    </div>

    <template v-if="question.special_type === 'medication'">
      <medication-question-type
        :question="question"
        :bloodthinnerQuestion="this.bloodthinnerQuestion"
        :questions="visibleQuestions"
        @setAnswer="(value) => this.$emit('setAnswer', value)"
      />
    </template>
  </div>
</template>

<script>
// This component name is a preexisting condition, disable inspection.
/* eslint vue/multi-word-component-names: 0 */
import { debounce } from 'lodash';
import videos from './Videos';
import languages from '@/languages';
import moment from 'moment';
import PersonalQuestionType from './SpecialQuestionTypes/PersonalQuestionType';
import InfoQuestionType from './SpecialQuestionTypes/InfoQuestionType';
import ScoreQuestionType from './SpecialQuestionTypes/ScoreQuestionType';
import DownloadQuestionnaireQuestionType from './SpecialQuestionTypes/DownloadQuestionnaireQuestionType';
import LanguageChoiceQuestionType from './SpecialQuestionTypes/LanguageChoiceQuestionType';
import AnesthesiaDescriptionQuestionType from './SpecialQuestionTypes/AnesthesiaDescriptionQuestionType';
import AnesthesiaChoiceQuestionType from './SpecialQuestionTypes/AnesthesiaChoiceQuestionType';
import MedicationQuestionType from './SpecialQuestionTypes/MedicationQuestionType';
import { mapKeys } from 'lodash';
import { resources } from '@/lib/resources';
import { isHeightQuestion, isWeightQuestion } from '@/lib/functions';

export default {
  components: {
    MedicationQuestionType,
    AnesthesiaChoiceQuestionType,
    AnesthesiaDescriptionQuestionType,
    LanguageChoiceQuestionType,
    DownloadQuestionnaireQuestionType,
    PersonalQuestionType,
    InfoQuestionType,
    ScoreQuestionType,
    videos,
  },
  props: [
    'closeScreening',
    'isLocal',
    'screening',
    'question',
    'enabled',
    'isLanguageRtl',
    'visibleQuestions',
  ],
  data() {
    return {
      applicableTechniques: null,
      anesthesiaVideos: null,
      moment,
      technique: null,
      languages,
      debouncedAnswer: null,
      answer: this.question.answer,
    };
  },
  computed: {
    bloodthinnerQuestion() {
      return this.visibleQuestions.find((q) => {
        return q.special_type === 'bloodthinner-options';
      });
    },
    operationSideLabel() {
      if (this.screening.operation_side === 'left') {
        return this.$t('left-side');
      }
      if (this.screening.operation_side === 'right') {
        return this.$t('right-side');
      }
      if (this.screening.operation_side === 'both') {
        return this.$t('both-sides');
      }
      return '';
    },
    preferredTechnique() {
      if (!this.applicableTechniques) {
        return null;
      }

      const techniques = this.applicableTechniques.filter((option) =>
        this.screening.anesthesia_preferences.includes(option.value)
      );

      return techniques.length > 0 ? techniques[0] : null;
    },
    chosenAnesthesia() {
      if (!this.applicableTechniques) {
        return '';
      }

      const technique = this.applicableTechniques.find(
        (t) => t.value === this.screening.chosen_anesthesia
      );

      return technique ? this.translate(technique, 'title') : '';
    },
    questionExplanation() {
      return this.translate(this.question, 'explanation', false);
    },
    questionText() {
      let text = this.translate(this.question, 'text');

      if (!text) {
        return text;
      }

      text = text.replaceAll(
        '{{PATIENT.NAME}}',
        this.screening.patient.fullname
      );
      text = text.replaceAll(
        '{{PATIENT.OPERATION}}',
        this.screening.patient_operation
      );
      text = text.replaceAll(
        '{{PATIENT.OPERATION_SIDE_IN_BRACKETS}}',
        this.operationSideLabel
      );
      text = text.replaceAll(
        '{{HOSPITAL.EMAIL}}',
        this.screening.tenant.hospital_email
      );
      text = text.replaceAll(
        '{{HOSPITAL.PHONE}}',
        this.screening.tenant.hospital_phone
      );
      text = text.replaceAll(
        '{{HOSPITAL.NAME}}',
        this.translate(this.screening.tenant, 'hospital_name')
      );
      text = text.replaceAll(
        '{{HOSPITAL.AVAILABILITY}}',
        this.translate(this.screening.tenant, 'hospital_availability')
      );
      text = text.replaceAll(
        '{{PATIENT.CHOSEN_ANESTHESIA}}',
        this.chosenAnesthesia
      );

      // backwards compatibility.
      text = text.replaceAll(
        '{{HOSPITAL.NAME_NL}}',
        this.translate(this.screening.tenant, 'hospital_name')
      );
      text = text.replaceAll(
        '{{HOSPITAL.NAME_EN}}',
        this.translate(this.screening.tenant, 'hospital_name')
      );
      text = text.replaceAll(
        '{{HOSPITAL.AVAILABILITY_NL}}',
        this.translate(this.screening.tenant, 'hospital_availability')
      );
      text = text.replaceAll(
        '{{HOSPITAL.AVAILABILITY_EN}}',
        this.translate(this.screening.tenant, 'hospital_availability')
      );

      return text;
    },
    locale() {
      return this.$i18n.locale;
    },
    isSmall() {
      return this.$mq === 'small';
    },
  },
  async created() {
    this.debouncedAnswer = debounce(this.setAnswer, 250);

    const [techniques, videos] = await Promise.all([
      this.getApplicableTechniques(),
      this.getAnesthesiaVideos(),
    ]);

    this.applicableTechniques = techniques;
    this.anesthesiaVideos = mapKeys(videos, 'name');
  },
  methods: {
    async getApplicableTechniques() {
      const techniques = await resources.getAnesthesiaTechniques();

      return techniques.filter((option) =>
        this.screening.anesthesia_methods.includes(option.value)
      );
    },
    async getAnesthesiaVideos() {
      return resources.getAnesthesiaVideos();
    },
    numberValidation(question) {
      if (isHeightQuestion(question)) {
        //length
        return 'between:100,250';
      }

      if (isWeightQuestion(question)) {
        //weight
        return 'between:20,400';
      }
      return null;
    },

    translate(item, key, fallback = true) {
      return languages.translate(this.$i18n.locale, item, key, fallback);
    },
    optionText(option) {
      return this.translate(option, 'text');
    },
    setAnswer() {
      this.$emit('setAnswer', { question: this.question, answer: this.answer });
    },
    isYesNo(question) {
      return (
        question.options.length === 2 && question.options[0].text.nl === 'Ja'
      );
    },
    onClick: function (option) {
      this.answer = option;
      this.debouncedAnswer();
    },
    onClickMulti: function (option) {
      let index = this.answer.indexOf(option);
      if (index === -1) {
        this.answer.push(option);
      } else {
        this.answer.splice(index, 1);
      }

      this.debouncedAnswer();
    },
    selected: function (option) {
      return this.answer.indexOf(option) !== -1;
    },
    click: function () {
      return false;
    },
    setNumberAnswer: function (event) {
      let input = event.target.value;
      input = parseInt(input);
      if (input < 0 || input > 400) {
        input = this.answer;
      }
      this.answer = -1;
      this.answer = input;
      this.debouncedAnswer();
    },
    toggleModal(question) {
      this.$emit('toggleModal', question);
    },
    getFlagUri(text) {
      return text === 'English'
        ? require('@/assets/flags/English.png')
        : require('@/assets/flags/Nederlands.png');
    },
  },
};
</script>

<style scoped lang="scss">
.question-row {
  display: flex;
}

.optionpointer {
}

.questioncol {
  width: 100%;
  flex: 12;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.infocol {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  line-height: 22.5px;
  min-width: 2rem;
  padding-left: 0.5rem;

  i {
    cursor: pointer;
  }
}

.answercol {
  flex: 7;
  display: flex;

  &.horizontal {
    align-items: flex-start;
    gap: 0.5rem;
  }
  &.vertical {
    flex-direction: column;

    > * + * {
      margin-top: 0.1rem;
    }
  }
}

.validation-message {
  font-size: 10px;
  color: red;
}

.question.future {
  opacity: 0.3;

  .optionsubtext {
    opacity: 0.3;
  }
}

.question.small {
  font-size: 100%;
  padding: 20px 0 0 0;

  .question-row {
    flex-direction: column;
    flex-wrap: wrap;
  }

  .info {
    display: flex;
    flex-direction: row;
    text-align: start;
    padding: 10px 5px 30px 5px;
  }

  .questioncol {
    padding: 0 0 15px 20px;
  }

  .answercol {
    display: flex;
    flex: 1;
    justify-content: center;
    padding: 10px 20px 20px 20px;
    text-align: center;

    .optionpointer.horizontal {
      //padding: 0 14px;
      margin-bottom: 10px;
      max-width: 10rem;
    }
  }

  textarea,
  input {
    max-width: 80% !important;
    margin: 0 10%;
  }
}

.question {
  .question-text {
    line-height: 22.5px;
    align-self: center;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
  }

  .btn,
  textarea,
  input {
    max-width: 300px;
  }

  padding: 40px 0 20px 0;

  .multipleanswers {
    font-style: italic;
    font-weight: bold;
  }

  .questioncol {
    //padding: 0 0 25px 30px;
    text-align: start;

    label {
      margin: 0;
    }
  }

  .answercol.wide {
    display: flex;
    flex-direction: column;

    .btn.answer {
      /*text-align: left !important;*/
      /*max-width: none;*/
      max-width: 90%;
    }
  }

  .answercol {
    text-align: start;
    padding: 0 30px;

    .optionpointer.vertical {
      .answer {
        width: 100%;
        margin-bottom: 10px;
        white-space: normal;
        display: inline-block;
      }
    }

    .optionpointer.horizontal {
      //display: inline-block;
      //padding: 0 15px 0 0;
      margin-bottom: 10px;
      display: flex;

      .answer {
        width: 100px;
        display: inline-block;
      }
    }
  }

  .pincode .digit {
    width: 80px;
    height: 80px;
    padding-top: 25px;
    font-size: 70px;
    text-align: center;
    border-radius: 10px;
  }

  .btn-novacair {
    background-color: $primary;
    color: $primary-content;

    &:hover {
      background-color: $primary-focus;
    }
  }

  .btn.answer.btn-novacair-selected {
    font-weight: bold;
  }

  .btn-novacair-light {
    border: 1px solid $primary;
    background-color: white;
    color: black;
    padding: 5px 30px;
  }

  .nextbutton {
    width: 250px;
  }

  .btn-novacair-selected,
  .btn-novacair-light:active {
    border: 1px solid $primary;
    background-color: $primary;
    color: white !important;
    padding: 5px 30px;
  }

  .btn-novacair-selected:hover {
    background-color: $primary-focus;
  }

  .btn-novacair-selected {
    &:visited,
    &:focus,
    &:hover,
    &:active,
    &:link {
      background-color: $primary-focus;
      box-shadow: rgba(0, 0, 0, 0.15) 3px 3px 6px;
    }
  }

  .buttoncol {
    text-align: center;

    .nextbutton-mobile {
      margin: 0 !important;
    }
  }
}
</style>
