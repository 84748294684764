<template>
  <div class="language-choice">
    <div class="info">
      <span style="white-space: pre-wrap" v-html="questionText"></span>
    </div>

    <div class="languages" :class="{ 'languages-mobile': isSmall }">
      <div
        class="optionpointer horizontal"
        :class="{ 'horizontal-mobile': isSmall }"
        v-for="language in screening.supported_languages"
        :key="language"
      >
        <button
          class="btn language-choice-button"
          @click="selectLanguage(language)"
          :class="
            answer === language ? 'btn-novacair-selected' : 'btn-novacair-light'
          "
          :disabled="!enabled"
        >
          <span class="language">
            {{ languages.localeMap[language] }}
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LanguageChoiceQuestionType',
  props: [
    'questionText',
    'isSmall',
    'screening',
    'answer',
    'enabled',
    'languages',
    'isLanguageRtl',
  ],
  methods: {
    selectLanguage(language) {
      this.setLocaleByLanguageOption(language);
      this.$emit('onClick', language);
    },

    setLocaleByLanguageOption(language) {
      this.$root.$i18n.locale = language;
      sessionStorage.setItem('locale', language);

      //Can also be next tick, if we want to use isLanguageRtl
      let htmlEl = document.querySelector('html');
      htmlEl.setAttribute('dir', language === 'ar' ? 'rtl' : 'ltr');
      htmlEl.setAttribute('lang', language);
    },
  },
};
</script>

<style scoped lang="scss">
.language-choice {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25px;

  .info {
    text-align: center;
  }

  .languages-mobile {
    flex-direction: column !important;
  }

  .languages {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;

    .horizontal-mobile {
      padding: 15px 12px 0 13px !important;
    }

    .optionpointer.horizontal {
      display: flex;
      flex-direction: row;
      padding: 30px 12px 0 13px;

      .language-choice-button {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: center;
        min-height: 45px;
        padding: 0 30px !important;
        width: 200px;
      }

      .btn-novacair-selected {
        font-weight: bold;
      }
    }
  }
}
</style>
