<template>
  <div class="row footer" :class="$mq">
    <div class="col-12 footer-content">
      <div v-if="section.show_progress" class="progressbar">
        <div
          class="progressfilled"
          v-bind:style="{ width: section.progress_percentage + '%' }"
        ></div>
      </div>
      <div class="buttons">
        <button
          v-if="section.allow_previous && hasPrevSection"
          type="button"
          class="btn prevbutton"
          :disabled="loadingSection"
          v-on:click="onPreviousSection"
        >
          <template v-if="loadingSection !== 'prev'">{{
            $t('previous')
          }}</template>
          <i
            v-if="loadingSection === 'prev'"
            class="fal fa-spinner fa-dark fa-spin"
          ></i>
        </button>
        <button
          v-if="sectionButtonText && section.button_position === 'footer'"
          type="button"
          class="btn nextbutton"
          v-on:click="onConfirmSection"
          :disabled="!isSectionFinished || loadingSection"
        >
          <template v-if="loadingSection !== 'next'">{{
            sectionButtonText
          }}</template>
          <i
            v-if="loadingSection === 'next'"
            class="fal fa-spinner fa-spin"
          ></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
// This component name is a preexisting condition, disable inspection.
/* eslint vue/multi-word-component-names: 0 */
import languages from '@/languages';

export default {
  props: [
    'hasPrevSection',
    'section',
    'isSectionFinished',
    'loadingSection',
    'isLanguageRtl',
  ],
  computed: {
    sectionButtonText() {
      return languages.translate(
        this.$i18n.locale,
        this.section,
        'button_text',
        false
      );
    },
  },
  methods: {
    onConfirmSection() {
      this.$emit('next');
    },
    onPreviousSection() {
      this.$emit('prev');
    },
  },
};
</script>

<style scoped lang="scss">
.footer.small {
  .footer-content {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .progressbar {
      width: calc(100% - 216px);
      margin: 8px 0 0;
    }

    .buttons {
      width: 216px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      .nextbutton {
        width: 95px;
        margin: 0;
      }

      .prevbutton {
        width: 95px;
        margin: 0;
      }
    }

    .buttons {
      .prevbutton:not(:only-child) {
        margin: 0 13px;
      }
    }
  }
}

.footer.small,
.footer.medium {
  height: 50px;
  .footer-content {
    .buttons {
      .nextbutton,
      .prevbutton {
        font-size: 15px;
        height: 35px;
      }
    }
  }
}

.footer {
  background-color: $neutral;
  margin: 0;

  .footer-content {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .buttons {
      width: 260px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      .nextbutton {
        color: $primary-content;
        background-color: $primary;
        box-shadow: rgba(0, 0, 0, 0.15) 3px 3px 6px;
        width: 110px;
        margin: 0 5px;
        font-weight: 600;
      }

      .nextbutton:visited,
      .nextbutton:focus,
      .nextbutton:hover,
      .nextbutton:active,
      .nextbutton:link {
        background-color: $primary-focus;
        box-shadow: rgba(0, 0, 0, 0.15) 3px 3px 6px;
      }

      .nextbutton:disabled {
        opacity: 0.4;
        background-color: $primary;
      }

      .prevbutton {
        width: 110px;
        background-color: $primary-content;
        box-shadow: rgba(0, 0, 0, 0.15) 3px 3px 6px;
        margin: 0;

        &:disabled {
          opacity: 0.4;
        }
      }

      .prevbutton:visited,
      .prevbutton:focus,
      .prevbutton:hover,
      .prevbutton:active,
      .prevbutton:link {
        background-color: $neutral-focus;
        box-shadow: rgba(0, 0, 0, 0.15) 3px 3px 6px;
      }
    }

    .buttons {
      .prevbutton:not(:only-child) {
        margin: 0 7px;
      }
    }

    .progressbar {
      width: calc(100% - 260px);
      margin: 8px 20px 0;
      height: 8px;
      background-color: white;

      .progressfilled {
        height: 8px;
        background-color: $accent-content;
      }

      .progressfilled.perc10 {
        width: 10%;
      }

      .progressfilled.perc30 {
        width: 30%;
      }

      .progressfilled.perc50 {
        width: 50%;
      }

      .progressfilled.perc80 {
        width: 80%;
      }
    }
  }
}
</style>
